import React from "react";

const About = () => {
	return (
		<section id="about-section" className="about">
			<br />

			<div className="tech-text">
				<u>TECH-STACK</u>
				<br />
				<br />
				TypeScript | React | Next.js | Node.js | Dotnet | C# | PostgreSQL |
				Google Cloud Platform | Svelte | Express.js | Redux | Redux Tool Kit |
				Gatsby | Contentful | Figma | Git | Github | MySQL | MongoDB | Mongoose
				| Vercel | GraphQL
				<br />
			</div>
		</section>
	);
};

export default About;
